<template>
  <land-section
    id="my_issue_video"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 900px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-card
            class="pa-14 rounded"
            color="white"
            flat
            tile
          >
            <span class="text-h5">发表视频</span>
            <div class="co-w-full co-h16 co-border-b co-border-gray-300" />
            <div class="co-w-full co-h40" />
            <el-upload
              v-if="wdForm.state === 'issue'"
              :ref="wdVideo.name"
              :action="wdVideo.action"
              :accept="wdVideo.accept"
              :headers="wdVideo.headers"
              :limit="wdVideo.limit"
              :file-list="wdVideo.files"
              :auto-upload="wdVideo.autoUpload"
              :on-exceed="onVideoExceed"
              :on-success="onVideoSuccess"
              :on-change="onVideoChange"
              :on-remove="onVideoRemove"
              :before-remove="onVideoBeforeRemove"
              :before-upload="onVideoBeforeUpload"
              class="video-uploader"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
                :disabled="btnSelect.disabled"
              >
                {{ btnSelect.text }}
              </el-button>
              <el-button
                v-if="btnUpload.visible"
                size="small"
                type="success"
                :disabled="btnUpload.disabled"
                :loading="btnUpload.loading"
                style="margin-left: 16px;"
                @click="onVideoUpload"
              >
                {{ btnUpload.text }}
              </el-button>
              <div
                slot="tip"
                class="co-text-xs blue-grey--text mt-2"
              >
                {{ wdVideo.tip }}
              </div>
            </el-upload>
            <div class="co-w-full co-h32" />
            <el-form
              v-if="wdForm.state === 'issue'"
              ref="frmVideo"
              :model="wdForm"
              :rules="wdRule"
              label-width="100px"
              label-position="top"
            >
              <el-form-item
                label="标题"
                prop="title"
              >
                <el-input
                  v-model="wdForm.title"
                  maxlength="35"
                  show-word-limit
                />
              </el-form-item>
              <div
                class="co-flex-col my-2"
              >
                <span
                  class="co-text-sm co-text-gray-700 mb-3"
                >
                  <span class="red--text">*</span>
                  封面
                </span>
                <el-upload
                  :ref="wdCover.name"
                  :action="wdCover.action"
                  :accept="wdCover.accept"
                  :headers="wdCover.headers"
                  :auto-upload="wdCover.autoUpload"
                  :show-file-list="false"
                  :before-upload="onCoverBeforeUpload"
                  :on-success="onCoverSuccess"
                  class="cover-uploader"
                >
                  <img
                    v-if="wdCover.value"
                    :src="wdCover.value"
                    class="cover-video"
                  >
                  <i
                    v-else
                    class="el-icon-plus cover-uploader-icon"
                  />
                </el-upload>
                <div
                  class="co-text-xs blue-grey--text mb-4"
                >
                  {{ wdCover.tip }}
                </div>
              </div>
              <el-form-item
                label="分享内容"
                prop="description"
              >
                <el-input
                  v-model="wdForm.description"
                  type="textarea"
                  maxlength="250"
                  rows="6"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                label="可见范围"
                prop="accessScope"
              >
                <el-select
                  v-model="wdForm.accessScope"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in wdScopes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <div class="co-flex-row co-justify-between co-items-center">
                  <el-button
                    :loading="btnIssue.loading"
                    type="primary"
                    style="width: 200px;"
                    @click="onSubmit"
                  >
                    {{ btnIssue.text }}
                  </el-button>
                  <div class="co-flex-row co-items-center">
                    <el-button
                      plain
                      @click="toIssueImage"
                    >
                      {{ btnIssueImg.text }}
                    </el-button>
                    <el-button
                      plain
                      @click="toIssueArticle"
                    >
                      {{ btnIssueArt.text }}
                    </el-button>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="wdForm.state !== 'issue'"
              class="co-flex-col co-justify-center co-items-center"
            >
              <v-icon
                size="160"
                :color="resultTip.color || 'grey'"
              >
                {{ resultTip.icon }}
              </v-icon>
              <span
                class="co-text-md blue-grey--text lighten-2 mt-4"
              >
                {{ resultTip.hint }}
              </span>
              <v-btn
                dark
                width="180"
                height="40"
                :color="resultTip.color"
                class="mt-8"
                @click="onNext"
              >
                {{ resultTip.next }}
              </v-btn>
              <div class="co-w-full co-h120" />
              <div class="co-flex-row co-justify-end co-items-center co-w-full">
                <el-button
                  plain
                  @click="toIssueImage"
                >
                  {{ btnIssueImg.text }}
                </el-button>
                <el-button
                  plain
                  @click="toIssueArticle"
                >
                  {{ btnIssueArt.text }}
                </el-button>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    metaInfo: { title: '发表视频' },
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        wdVideo: {
          name: 'upload_video',
          action: `${api.url.web_util_file_upload}?folder=video&type=video`,
          accept: '.mp4,.avi,.mov,.rmvb,.rm',
          autoUpload: true,
          tip: '只能上传mp4/avi/mov/rmvb文件，且视频文件不能超过500MB（选择视频后自动上传）。',
          headers: {},
          limit: 1,
          value: '',
          files: [],
          items: []
        },
        wdCover: {
          name: 'upload_cover',
          action: `${api.url.web_util_file_upload}?folder=image`,
          accept: '.jpg,.jpeg,.png,.gif',
          autoUpload: true,
          tip: '只能上传jpg/png/gif文件，且图片不能超过5MB，建议比例为16:9',
          headers: {},
          limit: 1,
          value: '',
          files: []
        },
        wdScopes: [],
        btnSelect: {
          disabled: false,
          text: '选择视频文件'
        },
        btnUpload: {
          visible: false,
          disabled: true,
          loading: false,
          text: '上传视频到服务器'
        },
        btnIssue: {
          loading: false,
          text: '发表'
        },
        btnIssueImg: {
          disabled: false,
          text: '去发表图片'
        },
        btnIssueArt: {
          disabled: false,
          text: '去发表文章'
        },
        wdForm: {
          state: 'issue',
          title: '',
          description: '',
          accessScope: '',
        },
        wdRule: {
          title: [
            { required: true, message: '请输入发表视频标题', trigger: 'blur' },
            { min: 2, max: 35, message: '长度在 2 到 35 个字符', trigger: 'blur' }
          ]
        },
        tips: {
          success: {
            value: 'success',
            icon: 'mdi-check-circle-outline',
            color: 'success',
            hint: '视频发表成功',
            next: '继续发表',
            handler: this.toContinueIssue
          },
          error: {
            value: 'error',
            icon: 'mdi-close-circle-outline',
            color: 'error',
            hint: '视频发表失败',
            next: '重新提交',
            handler: this.toReissue
          }
        },
        resultTip: {},
      }
    },
    created () {
      this.configHeaders()
      this.configScopes()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      setResultTip (val, message = '') {
        const tip = this.tips[val] || {}
        this.resultTip = Object.assign({}, tip)
        if (message) {
          this.resultTip.hint = `${tip.hint}(${message})`
        }
        this.wdForm.state = tip.value || 'issue'
      },
      toContinueIssue () {
        this.setResultTip('issue')

        const frmVideo = this.$refs[this.wdVideo.name]
        if (frmVideo) {
          frmVideo.clearFiles()
        }

        const frmCover = this.$refs[this.wdCover.name]
        if (frmCover) {
          frmCover.clearFiles()
        }

        this.wdVideo.items = []
        this.wdVideo.value = ''
        this.wdCover.value = ''

        this.wdForm.title = ''
        this.wdForm.description = ''
        this.checkVideoActions()
      },
      toReissue () {
        this.issueContent()
      },
      toIssueImage () {
        api.page.navigate(this, 'upload')
      },
      toIssueArticle () {
        api.page.navigate(this, 'issueArticle')
      },
      onVideoUpload () {
        this.uploadVideo()
      },
      configHeaders () {
        const obj = user.token.get()
        const headers = {
          token: obj.accessToken,
          uuid: obj.userId
        }

        this.wdVideo.headers = headers
        this.wdCover.headers = headers
      },
      configScopes () {
        this.wdScopes = []
        const descs = web.comm.ScopeDescs
        for (const i in descs) {
          const desc = descs[i]
          this.wdScopes.push({
            text: desc.text,
            value: desc.value
          })
        }
        const first = this.wdScopes[0]
        this.wdForm.accessScope = first.value
      },
      setTitleWithName (fileName) {
        if (!fileName) {
          return
        }
        const src = api.comm.trim(this.wdForm.title)
        if (src) {
          return
        }

        const arr = fileName.split('.')
        const dst = arr[0] || ''
        this.wdForm.title = api.comm.trim(dst)
      },
      checkVideoSelect () {
        const cnt = this.wdVideo.items.length
        this.btnSelect.disabled = !!cnt
      },
      checkVideoUpload () {
        const cnt = this.wdVideo.items.length
        this.btnUpload.disabled = !cnt
        if (cnt) {
          const first = this.wdVideo.items[0]
          const resp = first.response || {}
          const data = resp.data || {}
          const url = data.fileUrl || ''
          this.wdVideo.value = url
          this.btnUpload.disabled = !!url
        }
      },
      checkVideoActions () {
        this.checkVideoSelect()
        this.checkVideoUpload()
      },
      checkWidgets () {
        let result = ''
        // const arr = []
        if (this.wdVideo.items.length < 1) {
          // arr.push('必须选择一个视频')
          result = '必须选择一个视频'
        } else {
          if (!this.wdVideo.value) {
            // arr.push('视频正在上传')
            result = '视频正在上传'
          }
        }

        if (!this.wdForm.title) {
          // arr.push('必须填写标题')
          result = '必须填写标题'
        }

        // if (!this.wdCover.value) {
        //   arr.push('必须上传封面')
        // }

        // result = arr.join('，')
        return result
      },
      uploadVideo () {
        const frm = this.$refs[this.wdVideo.name]
        if (frm) {
          frm.submit()
        }
      },
      onVideoRemove (file, fileList) {
        // console.log({ file, fileList })
        this.wdVideo.items = fileList
        this.checkVideoActions()
      },
      onVideoBeforeRemove (file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`)
      },
      onVideoExceed (file, fileList) {
        console.log('onVideoExceed')
      },
      onVideoBeforeUpload (file) {
        // console.log('onVideoBeforeUpload')
        const isLt500M = file.size / 1024 / 1024 <= 500

        if (!isLt500M) {
          this.$notify({
            title: '错误提示',
            message: '上传视频文件大小不能超过 500MB!',
            type: 'error'
          })
        } else {
          this.btnUpload.loading = true
        }
        return isLt500M
      },
      onVideoSuccess (resp, file, fileList) {
        // console.log('onVideoSuccess')
        // console.log({ resp, file, fileList })
        this.btnUpload.loading = false
        if (resp.code === 200) {
          const data = resp.data || {}
          this.wdCover.value = data.videoCover
        }
      },
      onVideoChange (file, fileList) {
        // console.log('onVideoChange')
        // console.log({ file, fileList })
        this.wdVideo.items = fileList
        this.checkVideoActions()
        this.setTitleWithName(file.name)
      },
      onCoverSuccess (res, file) {
        // console.log({ res, file })
        const resp = file.response || {}
        const data = resp.data || {}
        this.wdCover.value = data.fileUrl || ''
      },
      onCoverBeforeUpload (file) {
        const isLt2M = file.size / 1024 / 1024 <= 5

        if (!isLt2M) {
          this.$notify({
            title: '错误提示',
            message: '上传封面图片大小不能超过 5MB!',
            type: 'error'
          })
        }
        return isLt2M
      },
      getData () {
        const content = {
          path: this.wdVideo.value
        }

        const result = {
          title: this.wdForm.title,
          description: this.wdForm.description,
          cover: this.wdCover.value,
          type: web.comm.ContentTypes.VIDEO,
          state: web.comm.States.PUBLISH,
          accessScope: this.wdForm.accessScope || web.comm.Scopes.PUBLIC,
          content: JSON.stringify(content)
        }
        return result
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executing = function () {
          me.btnIssue.loading = true
        }

        const executed = function (res) {
          me.btnIssue.loading = false
          if (res.status) {
            me.setResultTip('success')
            // me.$notify({
            //   title: '成功提示',
            //   message: '图片发表成功！',
            //   type: 'success'
            // })
          } else {
            const message = `${res.data.code} - ${res.data.desc}`
            me.setResultTip('error', message)
            // me.$notify({
            //   title: '发表失败',
            //   message,
            //   type: 'error'
            // })
          }
        }

        web.content.create({
          data,
          executing,
          executed
        })
      },
      onNext () {
        const handler = this.resultTip.handler
        if (typeof handler === 'function') {
          handler()
        }
      },
      onSubmit () {
        const message = this.checkWidgets()
        if (message) {
          this.showAlert('warning', message)
          // this.$notify({
          //   title: '错误提示',
          //   message,
          //   type: 'error'
          // })
          return
        }

        this.issueContent()
      }
    }
  }
</script>

<style>
  .video-uploader  a {
    color: #263238 !important;
    font-size: 16px !important;
  }

  .v-application ul {
    padding-left: 0 !important;
  }
  .v-application ol {
    padding-left: 0 !important;
  }

  .cover-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .cover-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .cover-uploader-icon {
    font-size: 32px;
    color: #8c939d;
    width: 320px;
    height: 180x;
    line-height: 180px;
    text-align: center;
  }
  .cover-video {
    width: 320px;
    height: 180x;
    display: block;
  }

  .el-form-item {
    margin-bottom: 26px !important;
  }

  .el-form-item__label {
    font-size: 16px !important;
    line-height: auto !important;
  }
</style>
